import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Marquee from "react-fast-marquee";
import Container from "./styled";

export default function Logos() {
  return (
    <Container>
      {/* Logos Container */}
      <div className="logos-container opacidad08">
        {/* Logo */}
        <a href="/prudencia-seguros" target="_top">
          <img
            alt="Prudencia Seguros Logo"
            src="/images/logos/prudenciaseguros.webp"
            id="prudencia-logo"
          ></img>
        </a>
        {/* Logo */}
        <a href="/federacion-patronal-seguros" target="_top">
          <img
            alt="Federación Patronal Seguros Logo"
            src="/images/logos/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
        </a>
        {/* Logo */}
        <a href="/la-segunda-seguros" target="_top">
          <img
            alt="La Segunda Seguros Logo"
            src="/images/logos/lasegunda.svg"
            id="lasegunda-logo"
          ></img>
        </a>
        {/* Logo */}
        <a href="https://www.sancorseguros.com.ar/" target="_top">
          <img
            alt="sancor Logo"
            src="/images/logos/sancor.png"
            id="sancor-logo"
          ></img>

        </a>
        {/* Logo */}
        <a href="https://integrityseguros.com.ar/" target="_top">
          <img
            alt="Integrity Logo"
            src="/images/logos/integrity.png"
            id="integrity-logo"
          ></img>

        </a>
      </div>
      {/* Marquee Container */}
      <Fade className="fade-container-marquee" delay={50} duration={700}>
        <Marquee gradient={true} speed="45">

          {/* Logo */}
          <img
            alt="Prudencia Seguros Logo"
            src="/images/logos/prudenciaseguros.webp"
            id="prudencia-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Federación Patronal Seguros Logo"
            src="/images/logos/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
          {/* Logo */}
          <img
            alt="La Segunda Seguros Logo"
            src="/images/logos/lasegunda.svg"
            id="lasegunda-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Prudencia Seguros Logo"
            src="/images/logos/prudenciaseguros.webp"
            id="prudencia-logo"
          ></img>

          {/* Logo */}
          <img
            alt="sancor Logo"
            src="/images/logos/sancor.png"
            id="sancor-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Federación Patronal Seguros Logo"
            src="/images/logos/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
          {/* Logo */}
          <img
            alt="La Segunda Seguros Logo"
            src="/images/logos/lasegunda.svg"
            id="lasegunda-logo"
          ></img>

          {/* Logo */}
          <img
            alt="Integrity Logo"
            src="/images/logos/integrity.png"
            id="integrity-logo"
          ></img>

        </Marquee>
      </Fade>
    </Container>
  );
}
