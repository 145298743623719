// *** Forma Parte *** //
export const formaParteMail = (inputValues) => {
    return {
        sendTo: "Recursos Humanos",
        subject: "Formá Parte SeguroBici",
        htmlContent: `
            <html>
                <body>
                    <h1>Formá Parte SeguroBici</h1>
                    <p><b>Nombre:</b> ${inputValues.nombre}</p>
                    <p><b>Apellido:</b> ${inputValues.apellido}</p>
                    <p><b>Email:</b> ${inputValues.email}</p>
                    <p><b>LinkedIn:</b> ${inputValues.linkedin}</p>
                    <p><b>Motivaciones:</b> ${inputValues.mensaje}</p>
                    <p>CV adjunto en este mensaje</p>
                </body>
            </html>
        `
    }
};

// *** Arrepentimiento *** //
export const arrepentimientoMail = (inputValues) => {
    return {
        sendTo: "Emisión",
        subject: "IMPORTANTE Botón de Arrepentimiento",
        htmlContent: `
            <html>
                <body>
                    <h1>Arrepentimiento de compra SeguroBici</h1>
                    <h2>Importante: Por ley debe ser respondido dentro de las próximas 24hs hábiles</h2>
                    <p><b>Nombre:</b> ${inputValues.nombre}</p>
                    <p><b>Apellido:</b> ${inputValues.apellido}</p>
                    <p><b>DNI:</b> ${inputValues.dni}</p>
                    <p><b>Poliza:</b> ${inputValues.poliza}</p>
                    <p>Si la póliza llego vacía, aún no le fue otorgada al usuario</p>
                    <p><b>Email:</b> ${inputValues.email}</p>
                    <p><b>Teléfono:</b> ${inputValues.telefono}</p>
                    <p><b>Comentario:</b> ${inputValues.mensaje}</p>
                </body>
            </html>
        `
    }
};

// *** Baja *** //
export const bajaMail = (inputValues) => {
    return {
        sendTo: "Emisión",
        subject: "IMPORTANTE Baja de Servicio",
        htmlContent: `
            <html>
                <body>
                    <h1>Baja de servicio SeguroBici</h1>
                    <h2>Importante: Por ley debe ser respondido dentro de las próximas 24hs hábiles</h2>
                    <p><b>Nombre:</b> ${inputValues.nombre}</p>
                    <p><b>Apellido:</b> ${inputValues.apellido}</p>
                    <p><b>DNI:</b> ${inputValues.dni}</p>
                    <p><b>Email:</b> ${inputValues.email}</p>
                    <p><b>Teléfono:</b> ${inputValues.telefono}</p>
                    <p><b>Motivo:</b> ${inputValues.mensaje}</p>
                </body>
            </html>
        `
    }
};

// *** Contacto *** //
export const contactoMail = (inputValues) => {
    return {
        sendTo: "Emisión",
        subject: "Contacto SeguroBici",
        htmlContent: `
            <html>
                <body>
                    <h1>Contaco SeguroBici</h1>
                    <p><b>Nombre:</b> ${inputValues.nombre}</p>
                    <p><b>Email:</b> ${inputValues.email}</p>
                    <p><b>Teléfono:</b> ${inputValues.telefono}</p>
                    <p><b>Asunto:</b> ${inputValues.asunto}</p>
                    <p><b>Comentario:</b> ${inputValues.mensaje}</p>
                </body>
            </html>
        `
    }
};

// *** Referidos *** //
export const referidosMail = (inputValues) => {
    return {
        sendTo: "Operadores",
        subject: "Referido SeguroBici",
        htmlContent: `
            <html>
                <body>
                    <h1>Referido SeguroBici</h1>
                    <h2>Datos del no cliente</h2>
                    <p><b>Email:</b> ${inputValues.email}</p>
                    <h2>Datos del referido</h2>
                    <p><b>Nombre:</b> ${inputValues.nombreReferido}</p>
                    <p><b>Email:</b> ${inputValues.emailReferido}</p>
                </body>
            </html>
        `
    }
};

// *** Red de Bicicleterias *** //
export const redBicicleteriasMail = (inputValues) => {
    return {
        sendTo: "Bicicleterias",
        subject: "Referido SeguroBici",
        htmlContent: `
            <html>
                <body>
                    <h2>Formá parte de la Red de Bicicleterias de SeguroBici</h2>
                    <p>Bicicleteria: ${inputValues.nombreBicicleteria}<p>
                    <p>Nombre y apellido: ${inputValues.nombreyapellido}<p>
                    <p>Email: ${inputValues.email}<p>
                    <p>Teléfono: ${inputValues.telefono}<p>
                </body>
            </html>
        `
    }
};