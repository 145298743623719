// Container
import { Container } from "./styled";
// Icons
import { IoMailOutline } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
// Librerias
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
// Form
import { redBicicleteriasForm } from "../../../utils/formInitialValues";
import { redBicicleteriasReglas } from "../../../utils/formRules";
// Mail
import { redBicicleteriasMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";
import { useState } from "react";

export default function FormularioBicicleterias() {

  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(handleSubmit, redBicicleteriasReglas, redBicicleteriasForm);
  const [textButton, setTextButton] = useState("Enviar mail")
  const [loading, setLoading] = useState(false)
 
  async function handleSubmit() {
    setTextButton("Enviando mail...")
    setLoading(true)
    try {
      const email = await sendFormMail(inputValues, null, redBicicleteriasMail)
      if(email){
        setLoading(false)
        setTextButton("¡Mail enviado!")
        setTimeout(() => {
          setTextButton("Enviar mail")
        }, 2000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container name="forma-parte-formulario">
      {/* Formulario Container */}
      <div id="form-titulo-container" className="opacidad05">
        {/* Titulos */}
        <div id="titulos-container">
          <h1 className="opacidad05">Formá parte de nuestra red</h1>
          <h3 className="opacidad05">¡Adherite y obtené beneficios!</h3>
        </div>
        {/* Formulario Container */}
        <div id="form-container" className="opacidad05">
          {/* Formulario */}
          <form>
            {/* Inputs Container */}
            <div id="inputs-container">
              {/* Input Nombre de la bicicletería */}
              <div
                className="input-container"
                id="input-nombre-bicicleteria-container"
              >
                <input
                  type="text"
                  id="inputNombreBicicleteria"
                  name="nombreBicicleteria"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombreBicicleteria}
                ></input>
                <label
                  className={
                    inputValues.nombreBicicleteria == null ||
                    inputValues.nombreBicicleteria == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="inputNombreBicicleteria"
                >
                  Bicicletería
                </label>
                {formErrors.nombreBicicleteria ? <span className="error-input">{formErrors.nombreBicicleteria}</span> : null}
              </div>
              {/* Input Nombre y Apellido */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="inputNombreApellido"
                  name="nombreyapellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombreyapellido}
                ></input>
                <label
                  className={
                    inputValues.nombreyapellido == null ||
                    inputValues.nombreyapellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="inputNombreApellido"
                >
                  Nombre y apellido
                </label>
                {formErrors.nombreyapellido ? <span className="error-input">{formErrors.nombreyapellido}</span> : null}
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="mail"
                  id="inputEmail"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="inputEmail"
                >
                  Email
                </label>
                {formErrors.email ? <span className="error-input">{formErrors.email}</span> : null}
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="num"
                  id="inputTelefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="inputTelefono"
                >
                  Teléfono
                </label>
                {formErrors.telefono ? <span className="error-input">{formErrors.telefono}</span> : null}
              </div>
            </div>
            {/* Botones Container */}
            <div id="botones-container">
              {/* Whatsapp Button */}
              <Tippy content="Días hábiles de 9 a 18hs" animation="scale">
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=5491162255399&text=%C2%A1Hola%2C%20necesito%20ayuda%20para%20cotizar%20una%20bicicleta!%20Ingres%C3%A9%20desde%20la%20Web"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  Necesito ayuda
                </a>
              </Tippy>
              {/* Cotizar Button */}
              <button
                type="submit"
                onClick={handleValidation}
                className="submit-button"
                disabled={loading}
              >
                {textButton}
                <span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Ilustracion Container */}
      <div className="ilustracion-container">
        <img
          alt="Red de Bicicleterias Ilustracion"
          src="/images/redDeBicicleterias/1.svg"
        ></img>
      </div>
    </Container>
  );
}
