import React from "react";
import "./styled.css";
import { FaWhatsapp } from "react-icons/fa";
import ReactGa from "react-ga";

const BotonFlotante = () => {
  const chatId = "5491162255399"; // El ID del chat de Facebook

  // Medición de clicks
  const medicionClick = () => {
    ReactGa.event({
      category: "WhatsApp",
      action: "Click",
    });
  };

  const handleClick = () => {
    medicionClick();
    window.open(
      `https://api.whatsapp.com/send?phone=${chatId}&text=Hola%2C%20estoy%20interesado%20en%20contratar%20un%20seguro`,
      "_blank"
    );
  };

  return (
    <div className="boton-flotante" onClick={handleClick}>
      <div className="icono-messenger">
        <FaWhatsapp size={24} />
      </div>
    </div>
  );
};

export default BotonFlotante;
